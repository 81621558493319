<template>
  <div class="table">
    <span class="title">题集详情</span>
    <el-form :inline="true" :model="form" style="margin-top: 20px">
      <el-form-item label="题集名称：" class="is-required">
        <el-input v-model="form.title" placeholder="请输入题集名称"></el-input>
      </el-form-item>
    </el-form>
    <el-row :gutter="10">
      <el-col :span="14">
        <span style="font-weight: bold">待选题目：</span>
        <el-row class="label-item-left" :gutter="10">
          <el-col style="margin-top: 10px" :span="7">
            <span style="font-size: 14px">标题： </span>
            <el-input
              v-model.trim="searchParams.title"
              clearable
              size="small"
              placeholder="请输入问题标题"
              style="width: 200px"
            />
          </el-col>
          <el-col style="margin-top: 10px" :span="7">
            <span style="font-size: 14px">ID：</span>
            <el-input
              v-model.trim="searchParams.problem_id"
              clearable
              size="small"
              placeholder="请输入问题ID"
              style="width: 200px"
            />
          </el-col>
          <el-col style="margin-top: 10px" :span="7">
            <el-button type="primary" size="small" @click.native="search"
              >查询</el-button
            >
            <el-button size="small" @click.native="reset">重置</el-button>
          </el-col>
        </el-row>
        <el-table
          ref="multipleTable"
          :data="dataList"
          border
          row-key="problem_id"
          fit
          highlight-current-row
          class="tb-list"
          style="margin-top: 10px"
          @selection-change="handleSeleChange"
        >
          <el-table-column
            :reserve-selection="true"
            type="selection"
            width="55"
            align="center"
          ></el-table-column>
          <el-table-column
            label="问题ID"
            align="center"
            width="120"
            prop="problem_id"
          ></el-table-column>
          <el-table-column
            show-overflow-tooltip
            label="问题标题"
            align="left"
            prop="title"
          >
            <template slot-scope="scope">
              <el-link
                type="primary"
                underline
                class="one-line"
                @click="quesDetail(scope.row)"
                >{{ scope.row.title }}
              </el-link>
            </template>
          </el-table-column>
          <el-table-column label="标签" align="left" prop="title" width="300">
            <template v-slot="{ row }">
              <el-tag
                size="mini"
                style="margin: 0 6px; border: none"
                v-for="item in row.tagList"
                :key="item.id"
                :color="item.groupInfo.color"
                effect="dark"
              >
                {{ item.title }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column label="提交记录" align="left" prop="title">
            <template slot-scope="scope">
              <span class="pointer" @click="subRecord(scope.row)"
                >{{ scope.row.accepted }}/{{ scope.row.submit }}</span
              >
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pagination.currentPage"
          :page-sizes="pagination.pageSizes"
          :page-size="pagination.pageSize"
          :layout="pagination.layout"
          :total="pagination.total"
        >
        </el-pagination>
      </el-col>
      <el-col :span="10">
        <span style="font-weight: bold">已选题目：</span>
        <el-table
          ref="dragTable"
          height="500"
          :data="multipleSelection"
          border
          fit
          row-key="problem_id"
          style="margin-top: 10px"
          highlight-current-row
          class="tb-list"
        >
          <el-table-column label="题号" align="center">
            <template slot-scope="scope">
              {{ scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column
            label="ID"
            align="center"
            prop="problem_id"
          ></el-table-column>
          <el-table-column
            label="标题"
            align="center"
            prop="title"
          ></el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <i
                class="el-icon-delete-solid"
                style="font-size: 16px; cursor: pointer"
                @click="deleteItem(scope.row, scope.$index)"
              ></i>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <div style="width: 100%; display: flex; justify-content: center">
      <el-button type="primary" style="margin-top: 20px" @click.native="confirm"
        >确定</el-button
      >
    </div>
  </div>
</template>
<script>
import {
  getSimpleQuestionList,
  addContestLibrary,
  updateContestLibrary,
} from "@/api/research/curriculum";
import heightSearch from "@/components/research/question-manage/heightSearch";
import Sortable from "sortablejs";
export default {
  components: { heightSearch },
  data() {
    return {
      searchParams: {
        title: "",
        problem_id: "",
      },
      form: {
        title: "",
      },
      editData: null,
      dataList: [],
      multipleSelection: [],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100],
        layout: "total, sizes, prev, pager, next, jumper",
        total: 0,
      },
      firstLoad: true,
      isEdit: false,
    };
  },

  watch: {},
  computed: {},
  created() {},
  mounted() {
    // 编辑
    if (this.$route.query.row) {
      this.editData = JSON.parse(this.$route.query.row);
      this.form.title = this.editData.title;
      this.isEdit = true;
    }
    this.$nextTick(() => {
      this.getSimpleQuestionList();
    });
  },
  methods: {
    search() {
      this.pagination.currentPage = 1;
      this.getSimpleQuestionList();
    },
    reset() {
      this.searchParams = {
        title: "",
        problem_id: "",
      };
    },
    async confirm() {
      if (!this.form.title) return $msg("请输入题集名称！", 2);
      if (!this.multipleSelection.length) return $msg("请选择题目！", 2);
      // addContestLibrary
      let questionIds = this.multipleSelection.map((item) => item.problem_id);
      if (this.isEdit) {
        this.updateContestLibrary(questionIds);
        return;
      }
      let { state } = await addContestLibrary({
        ...this.form,
        questionIds,
      });
      if (state == "success") {
        // this.$router.push({
        //   path: "/operation/operation",
        // });
        $msg("新增成功！");
        let tag = this.$store.getters.tag;
        this.$store.commit("DEL_TAG", tag);
        this.$router.go(-1);
      }
    },
    async updateContestLibrary(questionIds) {
      let { state } = await updateContestLibrary({
        id: this.editData.id,
        ...this.form,
        questionIds,
      });
      if (state == "success") {
        $msg("修改成功！");
        // this.$router.push({
        //   path: "/operation/operation",
        // });
        let tag = this.$store.getters.tag;
        this.$store.commit("DEL_TAG", tag);
        this.$router.go(-1);
      }
    },
    // 删除
    deleteItem(row, index) {
      this.$confirm("是否删除此题目", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          this.multipleSelection.splice(index, 1);
          this.$refs.multipleTable.toggleRowSelection(row, false);
          window.$msg("删除成功");
        })
        .catch(() => {
          return false;
        });
    },
    async getSimpleQuestionList() {
      let {
        state,
        body: { list, total },
      } = await getSimpleQuestionList({
        ...this.searchParams,
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        needCount: true,
      });
      if (state == "success") {
        this.dataList = list.map((item) => {
          return {
            ...item,
            flagNum: 0,
          };
        });
        this.pagination.total = total;
        if (this.firstLoad && this.$route.query.row) {
          this.firstLoad = false;
          this.multipleSelection = this.editData.questionList;
          console.log("this.multipleSelection", this.multipleSelection);
          this.$nextTick(() => {
            setTimeout(() => {
              console.log("this.$refs.multipleTable", this.$refs.multipleTable);
              this.$refs.multipleTable.clearSelection();
              this.multipleSelection.forEach((item) => {
                this.$refs.multipleTable.toggleRowSelection(item, true);
              });
              this.dataList = list.map((item) => {
                return {
                  ...item,
                  flagNum: item.flagNum++,
                };
              });
            }, 800);
          });
        }
      }
    },
    handleSeleChange(val) {
      this.multipleSelection = val;
    },
    // 分页
    handleCurrentChange(val) {
      this.pagination.currentPage = val;
      this.getSimpleQuestionList();
    },
    handleSizeChange(val) {
      this.pagination.pageSize = val;
      this.getSimpleQuestionList();
    },
  },
};
</script>
<style scoped lang="scss">
@import "../../style/table.scss";
</style>
