var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "table" },
    [
      _c("span", { staticClass: "title" }, [_vm._v("题集详情")]),
      _c(
        "el-form",
        {
          staticStyle: { "margin-top": "20px" },
          attrs: { inline: true, model: _vm.form },
        },
        [
          _c(
            "el-form-item",
            { staticClass: "is-required", attrs: { label: "题集名称：" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入题集名称" },
                model: {
                  value: _vm.form.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "title", $$v)
                  },
                  expression: "form.title",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 10 } },
        [
          _c(
            "el-col",
            { attrs: { span: 14 } },
            [
              _c("span", { staticStyle: { "font-weight": "bold" } }, [
                _vm._v("待选题目："),
              ]),
              _c(
                "el-row",
                { staticClass: "label-item-left", attrs: { gutter: 10 } },
                [
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: { span: 7 },
                    },
                    [
                      _c("span", { staticStyle: { "font-size": "14px" } }, [
                        _vm._v("标题： "),
                      ]),
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: {
                          clearable: "",
                          size: "small",
                          placeholder: "请输入问题标题",
                        },
                        model: {
                          value: _vm.searchParams.title,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.searchParams,
                              "title",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "searchParams.title",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: { span: 7 },
                    },
                    [
                      _c("span", { staticStyle: { "font-size": "14px" } }, [
                        _vm._v("ID："),
                      ]),
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: {
                          clearable: "",
                          size: "small",
                          placeholder: "请输入问题ID",
                        },
                        model: {
                          value: _vm.searchParams.problem_id,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.searchParams,
                              "problem_id",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "searchParams.problem_id",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: { span: 7 },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "small" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.search.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v("查询")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.reset.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v("重置")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  ref: "multipleTable",
                  staticClass: "tb-list",
                  staticStyle: { "margin-top": "10px" },
                  attrs: {
                    data: _vm.dataList,
                    border: "",
                    "row-key": "problem_id",
                    fit: "",
                    "highlight-current-row": "",
                  },
                  on: { "selection-change": _vm.handleSeleChange },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      "reserve-selection": true,
                      type: "selection",
                      width: "55",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "问题ID",
                      align: "center",
                      width: "120",
                      prop: "problem_id",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": "",
                      label: "问题标题",
                      align: "left",
                      prop: "title",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-link",
                              {
                                staticClass: "one-line",
                                attrs: { type: "primary", underline: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.quesDetail(scope.row)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(scope.row.title) + " ")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "标签",
                      align: "left",
                      prop: "title",
                      width: "300",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return _vm._l(row.tagList, function (item) {
                            return _c(
                              "el-tag",
                              {
                                key: item.id,
                                staticStyle: {
                                  margin: "0 6px",
                                  border: "none",
                                },
                                attrs: {
                                  size: "mini",
                                  color: item.groupInfo.color,
                                  effect: "dark",
                                },
                              },
                              [_vm._v(" " + _vm._s(item.title) + " ")]
                            )
                          })
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "提交记录", align: "left", prop: "title" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "span",
                              {
                                staticClass: "pointer",
                                on: {
                                  click: function ($event) {
                                    return _vm.subRecord(scope.row)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(scope.row.accepted) +
                                    "/" +
                                    _vm._s(scope.row.submit)
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.pagination.currentPage,
                  "page-sizes": _vm.pagination.pageSizes,
                  "page-size": _vm.pagination.pageSize,
                  layout: _vm.pagination.layout,
                  total: _vm.pagination.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                },
              }),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 10 } },
            [
              _c("span", { staticStyle: { "font-weight": "bold" } }, [
                _vm._v("已选题目："),
              ]),
              _c(
                "el-table",
                {
                  ref: "dragTable",
                  staticClass: "tb-list",
                  staticStyle: { "margin-top": "10px" },
                  attrs: {
                    height: "500",
                    data: _vm.multipleSelection,
                    border: "",
                    fit: "",
                    "row-key": "problem_id",
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "题号", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [_vm._v(" " + _vm._s(scope.$index + 1) + " ")]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "ID", align: "center", prop: "problem_id" },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "标题", align: "center", prop: "title" },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "操作", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("i", {
                              staticClass: "el-icon-delete-solid",
                              staticStyle: {
                                "font-size": "16px",
                                cursor: "pointer",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.deleteItem(scope.row, scope.$index)
                                },
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            width: "100%",
            display: "flex",
            "justify-content": "center",
          },
        },
        [
          _c(
            "el-button",
            {
              staticStyle: { "margin-top": "20px" },
              attrs: { type: "primary" },
              nativeOn: {
                click: function ($event) {
                  return _vm.confirm.apply(null, arguments)
                },
              },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }